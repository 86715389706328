<template>
  <div class="pasport">
    <h3 class="sub-title" v-if="!isEdit">Паспортные данные</h3>
    <h3 class="sub-title" v-else>Режим редактирования <span>Выберите параметр, для которого необходимо добавить сноску</span></h3>
    <v-skeleton-loader
     v-if="!load"
      class="mx-auto"
      type="table-tbody"
    ></v-skeleton-loader>
    <div class="table" v-else v-bind:class="{edit: isEdit}" :key="passportKey">
      <div class="table-row" v-for="pasport in getPasport" :key="pasport.label">
        <div class="label table-cell">{{pasport.label}}</div>
        <div class="value table-cell" @click="openNote({
            section: 'passportData',
            columnName: pasport.key,
            note: pasport.value.note ? pasport.value.note.name : ''
          })">
          <span>{{pasport.value ? pasport.value.value : ''}}</span>
          <div class="hint" v-if="pasport.value && pasport.value.note">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <!-- <v-icon
                  left
                  dark
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  x-small
                >
                  mdi-asterisk
                </v-icon> -->
                <span
                  v-bind="attrs"
                  v-on="on">[{{pasport.value.note.num}}]</span>
              </template>
              <span>[{{pasport.value.note.num}}] {{pasport.value.note.name}}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="redactor" v-if="!isEdit">
      <div class="button-box"
        v-if="
        (!($store.state.login.user.id == $store.state.smp.user || $store.state.login.mainDepartment || $store.state.login.cekkmp)
        && ($store.state.smp.statusTwo || $store.state.smp.statusOne)) || !$store.state.login.gust">
        <!-- <v-btn
          color="red"
          class="ma-2 white--text"
          @click="openPopup({
            standartId: $route.params.id,
            statusId: 'CB2D66F2-8F38-4047-ADA0-9F8CDE03391E',
            stageId: '0DD880C6-ACAA-4F97-9AA2-1E0AD441A5C9'
          }, 'Вы уверены, что хотите принять запрос на формирование проекта СМП?')"
          v-if="
          ($store.state.login.gbs || $store.state.login.cekkmp || $store.state.login.administrator) && $store.state.smp.statusOne"
        >
          Принять запрос
        </v-btn> -->

        <v-btn
          color="red"
          class="ma-2 white--text"
          @click="openPopup({
            standartId: $route.params.id,
            statusId: 'FB6DE7D6-A278-4D69-91DD-102899B25AC2',
            stageId: '783A93EA-AFF3-4D8F-91AC-5A690D4F4961'
          }, 'Вы уверены?')"
          v-if="
          ($store.state.login.administrator || $store.state.login.cekkmp)
          && ( $store.state.smp.statusTwo || $store.state.smp.statusThirteen)"
        >
          Проект сформирован
        </v-btn>
        <!-- <v-btn
          color="red"
          class="ma-2 white--text"
          @click="deliteDialog = true"
          v-if="
          ($store.state.login.administrator || $store.state.login.cekkmp)
          && ( $store.state.smp.statusTwo || $store.state.smp.statusThirteen || $store.state.smp.statusFourteen)"
          >
          Удалить проект
        </v-btn> -->

        <v-btn
          color="red"
          class="ma-2 white--text"
          @click="openPopup({
            standartId: $route.params.id,
            statusId: '1D403C69-4C12-4AD9-BAAA-3EA61F2EA684',
            stageId: '783A93EA-AFF3-4D8F-91AC-5A690D4F4961'
          }, 'Вы уверены?')"
          v-if="
          ($store.state.login.cekkmp || $store.state.login.administrator) && ($store.state.smp.statusTree || $store.state.smp.statusFive)"
        >
          Отправить на согласование
        </v-btn>


        <v-btn
          color="red"
          class="ma-2 white--text"
          @click="openPopup({
            standartId: $route.params.id,
            statusId: 'E75768B0-D4F7-44D8-8714-CE30E32AB36E',
            stageId: '783A93EA-AFF3-4D8F-91AC-5A690D4F4961'
          }, 'Вы уверены?')"
          v-if="
          ($store.state.login.user.id == $store.state.smp.user || $store.state.login.mainDepartment || $store.state.login.administrator) && $store.state.smp.statusSix"
        >
          Отправить предложения по доработке
        </v-btn>

        <v-btn
          color="red"
          class="ma-2 white--text"
          @click="openPopup({
            standartId: $route.params.id,
            statusId: 'DD2F4743-310B-460A-89B9-298504425567',
            stageId: '783A93EA-AFF3-4D8F-91AC-5A690D4F4961'
          }, 'Вы уверены?')"
          v-if="
          ($store.state.login.cekkmp || $store.state.login.administrator) && ($store.state.smp.statusTwelve)"
        >
          Отправить
        </v-btn>

             <v-btn
          color="red"
          class="ma-2 white--text"
          @click="openPopup({
            standartId: $route.params.id,
            statusId: '50DA9807-A277-49FF-94F3-4FF88DFE959E',
            stageId: '783A93EA-AFF3-4D8F-91AC-5A690D4F4961'
          }, 'Вы уверены?')"
          v-if="
          ($store.state.login.cekkmp || $store.state.login.administrator) && ($store.state.smp.statusEight)"
        >
          Предложения рассмотрены 
        </v-btn>
        <v-btn
          color="red"
          class="ma-2 white--text"
          @click="openPopup({
            standartId: $route.params.id,
            statusId: '7EF17CC8-295F-45A0-9BA9-03D9A6DCC331',
            stageId: '7E04AEB0-48C7-4880-838F-3D6DDD0724F2'
          }, 'Вы уверены, что хотите утвердить проект стандарта?')"
          v-if="
          ($store.state.login.user.id == $store.state.smp.user || $store.state.login.mainDepartment || $store.state.login.administrator) && ($store.state.smp.statusNine
          || $store.state.smp.statusSixteen || $store.state.smp.statusSix)"
        >
          Утвердить проект
          </v-btn>

        <v-btn
          color="red"
          class="ma-2 white--text"
          @click="openPopup({
            standartId: $route.params.id,
            statusId: '2C970AF2-74D6-44CF-A546-72AFB38C37A2',
            stageId: '783A93EA-AFF3-4D8F-91AC-5A690D4F4961'
          }, 'Проект СМП необходимо доработать до:')"
          v-if="
          ($store.state.login.user.id == $store.state.smp.user || $store.state.login.mainDepartment || $store.state.login.administrator) && ($store.state.smp.statusNine
          || $store.state.smp.statusSixteen)"
        >
          Отправить на доработку
        </v-btn>

        <v-btn
          color="red"
          class="ma-2 white--text"
          @click="openPopup({
            standartId: $route.params.id,
            statusId: 'A41BA4D1-7673-45D9-8BE7-45F5FBC53252',
            stageId: '783A93EA-AFF3-4D8F-91AC-5A690D4F4961'
          }, 'Вы уверены?')"
          v-if="
          ($store.state.login.cekkmp || $store.state.login.administrator) && $store.state.smp.statusEleven"
        >
          Проект доработан
        </v-btn>

        <v-btn
          color="white"
          class="ma-2 red--text"
          @click="setEditor()"
          v-if="
          ($store.state.login.cekkmp
          || $store.state.login.administrator)
            && ($store.state.smp.statusThirteen || $store.state.smp.statusTwo || $store.state.smp.statusEleven
            || $store.state.smp.statusFourteen)"
        >
        <v-icon
          left
          dark
        >
          mdi-pencil-outline
        </v-icon>
          Редактировать
        </v-btn>

        <v-btn
          color="white"
          class="ma-2 red--text"
          v-if="
          ($store.state.login.administrator
            || $store.state.login.cekkmp)
          && ($store.state.smp.statusThirteen || $store.state.smp.statusTwo    || $store.state.smp.statusEleven
          || $store.state.smp.statusFourteen)"
        >
          <label for="file">
            <v-icon
              left
              dark
            >
              mdi-file-code-outline
            </v-icon>
            Загрузить JSON
          </label>
        </v-btn>

        <input type="file" id="file" @change="saveJson" ref="file" class="file d-none" accept="application/JSON">
        <v-btn
          color="white"
          class="ma-2 red--text"
          v-if="
          ($store.state.login.administrator
            || $store.state.login.cekkmp)
          && ($store.state.smp.statusCanClone)"
          @click="openCloneStdWindow"
        >
          <label>
            <v-icon
              left
              dark
            >
              mdi-content-copy
            </v-icon>
            Создать проект стандарта
          </label>
        </v-btn>
        <input type="file" id="file2" @change="saveJsonAsCopy" ref="file2" class="file d-none" accept="application/JSON">
        <v-btn
          color="white"
          class="ma-2 red--text"
          @click="generateFile({id: $route.params.id, file: 'GenerateWord', sufix: '.Docx', code: getWordName.value})"
        >
        <v-icon
          left
          dark
        >
          mdi-microsoft-word
        </v-icon>
          Сформировать Word
        </v-btn>

        <!-- <v-btn
          color="white"
          class="ma-2 red--text"
          @click="generateFile({id: $route.params.id, file: 'GenerateExcel', sufix: '.xls', code: getWordName.value})"
        >
          <v-icon
            left
            dark
          >
            mdi-microsoft-excel
          </v-icon>
          Сформировать Excel
        </v-btn> -->
      </div>
    </div>
    <div class="redactor-open" v-else>
      <div class="bitton-box">
        <v-btn
          color="white"
          class="ma-2 red--text"
          @click="setEditor()"
          v-if="
          $store.state.login.cekkmp
          || $store.state.login.user.id == $store.state.smp.user || $store.state.login.mainDepartment
          || $store.state.login.administrator"
        >
        <v-icon
          left
          dark
        >
          mdi-pencil-outline
        </v-icon>
          Сохранить
        </v-btn>
      </div>
    </div>
    <v-dialog
      v-model="clonestddialog"
      max-width="558"
      class="dialog"
      >
      <v-card>
        <v-card-title class="text-h6" v-bind:class="{red: error}">
          {{dialogText}}
        </v-card-title>
        <v-spacer></v-spacer>

        <v-card-actions class='justify-space-between' v-if="ask">
          <v-btn
            color="red darken-1"
            text
            @click="clonestddialog = false"
          >
            Отменить
          </v-btn>

          <v-btn
            color="green darken-1"
            text
          >
            <label for="file2">
              <v-icon
                left
                dark
              >
                mdi-file-code-outline
              </v-icon>
              Создать
            </label>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deliteDialog"
      max-width="558"
      class="dialog"
      >
      <v-card>
        <v-card-title class="text-h6" v-bind:class="{red: error}">
          {{modalText}}
        </v-card-title>
        <v-spacer></v-spacer>

        <v-card-actions class='justify-space-between' v-if="modalText === 'Вы действительно хотите удалить проект'">
          <v-btn
            color="red darken-1"
            text
            @click="deliteDialog = false"
          >
            Отменить
          </v-btn>

          <v-btn
            color="green darken-1"
            @click="deletePopup($route.params.id)"
            text
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog"
      max-width="320"
      class="dialog"
      >
      <v-card>
        <v-card-title class="text-h6" v-bind:class="{red: error}">
          {{dialogText}}
        </v-card-title>
        <v-spacer></v-spacer>

        <v-card-text v-if="query.statusId === '2C970AF2-74D6-44CF-A546-72AFB38C37A2'">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Дата"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-card-text>

        <v-card-actions class='justify-space-between' v-if="ask">
          <v-btn
            color="red darken-1"
            text
            @click="dialog = false"
          >
            Отменить
          </v-btn>
          <v-btn
           v-if="query.statusId === 'CB2D66F2-8F38-4047-ADA0-9F8CDE03391E'"
            color="green darken-1"
            text
            @click="statusUpdate(query)"
          >
            Принять
          </v-btn>
          <v-btn
            v-else-if="query.statusId === '2C970AF2-74D6-44CF-A546-72AFB38C37A2'"
            color="green darken-1"
            text
            @click="saveData()"
          >
            Отправить
          </v-btn>
          <v-btn
            v-else
            color="green darken-1"
            text
            @click="statusUpdate(query)"
          >
            Отправить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="addNoteDialog"
      max-width="558"
      class="addnote"
      >
      <v-card>
        <v-card-title class="text-h6">
        </v-card-title>
        <v-spacer></v-spacer>
        <v-textarea
          solo
          name="input-7-4"
          label="Введите заметку"
        ></v-textarea>

        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            @click="addNoteDialog = false"
          >
            Выбрать заметку из справочника
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="addNewNote(queryNote)"
          >
            Добавить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogNote"
      max-width="558"
     >
      <v-card>

        <v-card-title class="text-h6">
          <div>Добавить заметку</div>

          <v-autocomplete
            v-if="searchNote"
            :items="getNotes2"
            label="Поиск по заметкам"
            v-model="queryNote.name"
          ></v-autocomplete>
        </v-card-title>

        <v-textarea
          name="textarea"
          class="textarea"
          outlined
          v-model="queryNote.name"
          label="Введите заметку"
        ></v-textarea>
        <v-card-actions>
          <v-btn
            color="primary darken-1"
            text
            @click="searchNote = !searchNote"
          >
            Выбрать заметку из справочника
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            @click="addNewNote()"
          >
            Добавить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {mapGetters, mapActions, mapMutations} from 'vuex'

  export default {
    name: 'Pasport',
    data () {
      return {
        query: {
          statusId: null,
          stageId:null
        },
        deliteDialog: false,
        load: false,
        modalText: 'Вы действительно хотите удалить проект',
        passportKey: 0,
        menu2: false,
        queryNote: {
          standartId: '',
          name: '',
          typeCode: 1,
          section: 'passportData',
          linkName: "",
          columnName: '',
          filterName: "",
          filterPage: '',
          filterFreq: "",
          filterCountSKD: "",
          filterUnit: "",
          filterSDD: "",
          filterATCCode: ""
        },
        searchNote: false,
        ask: false,
        date: null,
        dialog: false,
        dialogNote: false,
        dialogText: 'Json успешно загружен',
        error: false,
        addNoteDialog: false,
        clonestddialog: false,
      }
    },
    computed : {
      ...mapGetters({
          getPasport: 'smp/getPasport',
          isEdit: 'smp/isEdit',
          getWordName: 'smp/getWordName',
          getId: 'smp/getId',
          getNotes2: 'lookups/getNotes2'
      }),
    },
    methods: {
      ...mapMutations({
          setEditor: 'smp/setEditor'
      }),
      ...mapActions({
          deleteStandart: 'smp/deleteStandart',
          smpDate: 'smp/smpDate',
          upLoadJson: 'smp/upLoadJson',
          standartUpdate: 'smp/standartUpdate',
          standart: 'smp/standart',
          generateFile: 'smp/generateFile',
          addNote: 'smp/addNote',
          notes: 'lookups/notes'
      }),
      deletePopup(id) {
        this.deleteStandart(id).then(res => {
          this.modalText = res.data ? 'Проект удален' : 'Ошибка'

          if (res.data) {
            setTimeout(() => {
              this.$router.push({ name: 'Home' })
            },1000)
          }
        })
      },
      openPopup(data, text) {
        this.ask = true
        this.dialog = true
        this.dialogText = text
        this.query = data
      },
      statusUpdate(data){
        this.standartUpdate(data).then(res => {
          this.ask = false
          this.dialog = true
          if (res.status == 200) {
            this.dialogText = 'Успешно'
            this.standart(this.$route.params.id)
            this.error = false
          } else {
            this.dialogText = `Ошибка ${res.status}`
            this.error = true
          }
          setTimeout(() => {
            this.dialog = false
          }, 2000)
        }).catch(err => {
          this.dialogText = `${err}`
          this.error = true
          this.ask = false

          setTimeout(() => {
            this.dialog = false
          }, 2000)
        });
      },
      saveJson() {
        this.upLoadJson({StandartId: this.$route.params.id, File: this.$refs.file.files[0]}).then(res => {
          this.dialog = true
          if (res.status == 200) {
            this.dialogText = 'Json успешно загружен'
            this.standart(this.$route.params.id)
            this.error = false
          } else {
            this.dialogText = 'Ошибка при загрузке'
            this.error = true
          }
          setTimeout(() => {
            this.dialog = false
          }, 2000)
        })
      },
      openCloneStdWindow(){
        this.clonestddialog = true
        this.red = false
        this.ask = true
        this.dialogText = 'Кнопка "Создать проект стандарта" предназначена для загрузки еще одного проекта стандарта в данный запрос. \
          Вы уверены, что хотите загрузить еще один проект стандарта?'
      },
      saveData() {
        this.smpDate({id:this.$route.params.id, date: this.date}).then(res => {
          this.ask = false
          if (res.status == 200) {
            this.dialogText = 'Успешно'
            this.standart(this.$route.params.id)
            this.error = false
          } else {
            this.dialogText = `Ошибка ${res.status}`
            this.error = true
          }
          setTimeout(() => {
            this.dialog = false
          }, 2000)
        }).catch(err => {
          this.dialogText = `${err}`
          this.error = true
          this.ask = false

          setTimeout(() => {
            this.dialog = false
          }, 2000)
        });
      },
      saveJsonAsCopy(){
        //clonestddialog
        this.upLoadJson({StandartId: this.$route.params.id, MakeCopy: true, File: this.$refs.file2.files[0]}).then(res => {
          this.clonestddialog = true
          this.ask = false
          if (res.status == 200) {
            this.dialogText = 'Json успешно загружен'
            this.standart(res.data.passportData.id)
            this.$router.push("/smp/pasport/"+res.data.passportData.id)
            this.error = false
          } else {
            this.dialogText = 'Ошибка при загрузке'
            this.error = true
          }
          setTimeout(() => {
            this.clonestddialog = false
          }, 2000)
        })
      },
      addNewNote() {
        this.dialogNote = false
        this.addNote(this.queryNote).then(()=> {
          this.standart(this.$route.params.id)
          this.passportKey++
        })
      },
      openNote(data) {
        if (this.isEdit) {
          this.dialogNote = true
          this.queryNote.standartId = this.getId
          this.queryNote.name = data.note || ''
          this.queryNote.typeCode = 1
          this.queryNote.columnName = data.columnName
        } else {
          return false
        }
      },
    },
    mounted() {
      this.notes().then(() => this.load = true)
    }
  }
</script>

<style lang="scss" scoped>
@import "~@/scss/vars.scss";
@import "~@/scss/mixins.scss";
@import "./style.scss";
</style>
